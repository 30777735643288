/**
 * Represents a track that is to be merged with other audios
 */
var Track = /** @class */ (function () {
    /**
     * Creates an instance of Track.
     * @param {AudioContext} audioCtx
     * @memberof Track
     */
    function Track(audioCtx) {
        /**
         * A type of AudioNode which operates as an audio source whose media is received
         * from a MediaStream obtained using the WebRTC or Media Capture and Streams APIs
         *
         * @type {(MediaStreamAudioSourceNode | MediaElementAudioSourceNode | null)}
         * @memberof Track
         */
        this.id = this.uuid()
        this.source = null
        this.audioCtx = audioCtx
        this.sound = null
        this.gainNode = null
        this.analyser = null
        this.stream = null
        
    }

    Track.prototype.Id = function () {
        return this.id
    }

    Track.prototype.uuid = function () {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
    
        var uuid = s.join("");
        return uuid;
    }
    

    Track.prototype.createFromStream = function (stream) {
        const source = this.audioCtx.createMediaStreamSource(stream);
        this.stream = stream
        this.merge(source)
    };
    
    
    Track.prototype.createFromElement = function (mediaElement) {
        const source = this.audioCtx.createMediaElementSource(mediaElement);
        this.merge(source)
    };

    Track.prototype.disconnect = function (source, close) {
        this.gainNode.disconnect(source)
        if(close && this.stream!= null){
            this.stream.getAudioTracks()[0].stop()
        }
    };

    Track.prototype.connect = function (source) {
        this.gainNode.connect(source)
    };

    Track.prototype.getStream = function () {
        return this.stream
    };

    Track.prototype.merge = function (source) {
        this.source = source
        this.analyser = this.audioCtx.createAnalyser();
        this.gainNode = this.audioCtx.createGain();
        this.source.connect(this.analyser);
        this.source.connect(this.gainNode);
    };
    return Track;
}());
export default Track;
