import m4 from './m4';
var JsVideoTrackWebgl = /** @class */ (function () {

    function JsVideoTrackWebgl(videoMerge, ctx, drawConfig) {
        this.videoRead = null
        this.track = null
        this.videoMerge = videoMerge
        this.stream = null
        this.emptyNum = 0
        this.drawInfo = null
        this.drawConfig = drawConfig
        this.ctx = ctx
    }

    JsVideoTrackWebgl.prototype.initPre = function (preTrack) {
        this.preTrack = preTrack
    };

    JsVideoTrackWebgl.prototype.initNext = function (nextTrack) {
        this.nextTrack = nextTrack
    };


    JsVideoTrackWebgl.prototype.createFromStream = function (stream, ...position) {
        this.positionSet(position)
        this.stream = stream
        this.track = stream.getVideoTracks()[0].clone()
        if (this.track.readyState == "live") {
            const processor = new MediaStreamTrackProcessor(this.track);
            this.videoRead = processor.readable
            this.loop()
        } else {
            //主流已经被销毁，移除该流
            this.videoMerge.removeTrack(this, false)
        }
    };

    JsVideoTrackWebgl.prototype.createTexture = function (chunk, texture) {
        const gl = this.ctx
        if (texture == null) {
            texture = gl.createTexture();
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 1, 1, 0, gl.RGBA, gl.UNSIGNED_BYTE, this.drawConfig.array);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        }else{
            gl.bindTexture(gl.TEXTURE_2D, texture);
        }
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, chunk);
        return texture
    }

    JsVideoTrackWebgl.prototype.loop = function () {
        const that = this
        const stream = new WritableStream({
            start() {
            },
            write(chunk) {
                const texture = that.createTexture(chunk, that.drawInfo ? that.drawInfo.texture : null)
                const drawInfo = {
                    texture: texture,
                    width: chunk.displayWidth,
                    height: chunk.displayHeight,
                    close: function () {
                        // this.texture = null
                    }
                }
                chunk.close()
                if (that.drawInfo != null) {
                    const oldDrawInfo = that.drawInfo
                    oldDrawInfo.close()
                }
                that.drawInfo = drawInfo
            },
            close() {
                if (that.drawInfo != null) {
                    that.drawInfo.close()
                }
                that.drawInfo = null
            }
        });
        this.videoRead.pipeTo(stream)
    };

    JsVideoTrackWebgl.prototype.disconnect = function (close) {
        if (this.track != null) {
            this.track.stop()
        }
        if (close && this.stream != null) {
            this.stream.getVideoTracks()[0].stop()
        }
        this.ctx = null
    };

    JsVideoTrackWebgl.prototype.disconnectAll = function (close) {
        this.disconnect(close)
        if (this.nextTrack != null) {
            this.nextTrack.disconnectAll(close)
        }
        this.ctx = null
    };

    JsVideoTrackWebgl.prototype.positionSet = function (position) {
        this.position = position
    }

    JsVideoTrackWebgl.prototype.changePosition = function (...position) {
        this.positionSet(position)
    }

    JsVideoTrackWebgl.prototype._draw = function (width, height, showWidth, showHeight) {
        const gl = this.ctx
        gl.bindTexture(gl.TEXTURE_2D, this.drawInfo.texture);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.drawConfig.positionBuffer);
        gl.enableVertexAttribArray(this.drawConfig.positionLocation);
        gl.vertexAttribPointer(this.drawConfig.positionLocation, 2, gl.FLOAT, false, 0, 0);
        gl.bindBuffer(gl.ARRAY_BUFFER, this.drawConfig.texcoordBuffer);
        gl.enableVertexAttribArray(this.drawConfig.texcoordLocation);
        gl.vertexAttribPointer(this.drawConfig.texcoordLocation, 2, gl.FLOAT, false, 0, 0);
        var matrix = m4.orthographic(0, showWidth, showHeight, 0, -1, 1);
        matrix = m4.translate(matrix, this.position[0], this.position[1], 0);
        matrix = m4.scale(matrix, width, height, 1);
        gl.uniformMatrix4fv(this.drawConfig.matrixLocation, false, matrix);
        gl.uniform1i(this.drawConfig.textureLocation, 0);
        gl.drawArrays(gl.TRIANGLES, 0, 6);
    }

    JsVideoTrackWebgl.prototype.draw = function (ctx) {
        if (this.drawInfo != null) {
            const canvasWidth = this.ctx.canvas.width
            const canvasHeight = this.ctx.canvas.height
            const showWidth = this.position[2] ? (this.position[2] + this.position[0]) : ctx.canvas.width
            const showHeight = this.position[3] ? (this.position[3] + this.position[1]) : ctx.canvas.height
            const dWidth = showWidth - this.position[0]
            const dHeight = showHeight - this.position[1]
            const imgScaleWidth = dWidth / canvasWidth
            const imgScaleHeight = dHeight / canvasHeight
            const width = showWidth * imgScaleWidth
            const height = showHeight * imgScaleHeight
            this._draw(width, height, showWidth, showHeight)
        } else {
            this.emptyNum++
            if (this.emptyNum == 10) {
                this.track.stop()
                this.createFromStream(this.stream, ...this.position)
                this.emptyNum = 0
            }
        }
        if (this.nextTrack != null) {
            return this.nextTrack.draw(ctx)
        }
        return true
    }

    return JsVideoTrackWebgl;
}())

export default JsVideoTrackWebgl;