import videoTrackWebgl from './videoTrackWebgl';
import webglUtils from './webgl-utils';
require("hacktimer");
var JsVideoMergerWebgl = /** @class */ (function () {

    function JsVideoMergerWebgl(canvas) {
        this.Supported(canvas)
        this.glConfig = {array: new Uint8Array([0, 0, 255, 255])}
        this.init()
        this.stream = this.canvas.captureStream(30)
        this.lastTrack = null
        this.firstTrack = null
        this.intervalId = null
        
    }

    JsVideoMergerWebgl.prototype.Supported = function (canvas) {
        var canvas = document.querySelector("#canvas");
        var gl = canvas.getContext("webgl2");
        if (!gl) {
            throw "not supported webgl"
        }
        gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
        this.canvas = canvas
        this.ctx = gl
        return true
    }

    JsVideoMergerWebgl.prototype.init = function () {
        const gl = this.ctx;
        const vertexShaderSource = `attribute vec4 a_position;
        attribute vec2 a_texcoord;
        
        uniform mat4 u_matrix;
        
        varying vec2 v_texcoord;
        
        void main() {
           gl_Position = u_matrix * a_position;
           v_texcoord = a_texcoord;
        }`
        const fragmentShaderSource = ` precision mediump float;
        
        varying vec2 v_texcoord;
        
        uniform sampler2D u_texture;
        
        void main() {
           gl_FragColor = texture2D(u_texture, v_texcoord);
        }`
        this.glConfig.program = webglUtils.createProgramFromSources(gl, [vertexShaderSource, fragmentShaderSource]);
        // look up where the vertex data needs to go.
        this.glConfig.positionLocation = gl.getAttribLocation(this.glConfig.program, "a_position");
        this.glConfig.texcoordLocation = gl.getAttribLocation(this.glConfig.program, "a_texcoord");

        // lookup uniforms
        this.glConfig.matrixLocation = gl.getUniformLocation(this.glConfig.program, "u_matrix");
        this.textureLocation = gl.getUniformLocation(this.glConfig.program, "u_texture");

        // Create a buffer.
        this.glConfig.positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.glConfig.positionBuffer);

        // Put a unit quad in the buffer
        var positions = [
            0, 0,
            0, 1,
            1, 0,
            1, 0,
            0, 1,
            1, 1,
        ];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

        // Create a buffer for texture coords
        this.glConfig.texcoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.glConfig.texcoordBuffer);

        // Put texcoords in the buffer
        var texcoords = [
            0, 0,
            0, 1,
            1, 0,
            1, 0,
            0, 1,
            1, 1,
        ];
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(texcoords), gl.STATIC_DRAW);
        gl.useProgram(this.glConfig.program);
    }

    /**
     *  从MediaStream获取视频流
     * @param {*} stream 
     * @param  {...any} position 
     * @returns 
     */
    JsVideoMergerWebgl.prototype.addFromStream = function (stream, ...position) {
        if (!stream.getVideoTracks().length) {
            throw new Error('The stream does not have any video tracks');
        }
        this.ctx.viewport(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
        const track = new videoTrackWebgl(this, this.ctx, this.glConfig);
        track.createFromStream(stream, ...position)
        if (this.firstTrack == null) {
            track.initPre(null)
            track.initNext(null)
            this.firstTrack = track
            this.lastTrack = track
            this.draw(this)
        } else {
            track.initPre(this.lastTrack)
            this.lastTrack.initNext(track)
            this.lastTrack = track
        }
        return track
    };

    /**
     *  从MediaElement标签（audio，video等）获取
     * @param {*} stream 
     * @param  {...any} position 
     * @returns 
     */
    JsVideoMergerWebgl.prototype.addFromElement = function (el, ...position) {
        const stream = el.captureStream();
        if (stream == null || !stream.getVideoTracks().length) {
            throw new Error('The stream does not have any video tracks');
        }
        return this.addFromStream(stream, ...position)
    };

    /**
     * 获取合成的视频流
     * @returns 
     */
    JsVideoMergerWebgl.prototype.getStream = function () {
        return this.stream
    };

    JsVideoMergerWebgl.prototype.draw = (that) => {
        if (that.intervalId != null) {
            window.clearInterval(that.intervalId);
        }
        const anima = () => {
            if (that.canvas != null) {
                if (that.firstTrack != null) {
                    that.ctx.viewport(0, 0, that.ctx.canvas.width, that.ctx.canvas.height);
                    that.ctx.clear(that.ctx.COLOR_BUFFER_BIT);
                    that.firstTrack.draw(that.ctx)
                    return
                }
            }
        }
        that.intervalId = window.setInterval(anima, 1000 / 30);

    };

    /**
     * 移除视频流
     * @param {} track 
     * @param {*} close 
     * 是否关闭创建的视频流
     */
    JsVideoMergerWebgl.prototype.removeTrack = function (track, close) {
        if (this.firstTrack == track) {
            this.firstTrack = track.nextTrack
        }
        if (this.lastTrack == track) {
            this.lastTrack = track.preTrack
        }
        const preTrack = track.preTrack
        const nextTrack = track.nextTrack
        if (preTrack != null) {
            preTrack.initNext(nextTrack)
        }
        if (nextTrack != null) {
            nextTrack.initPre(preTrack)
        }
        track.disconnect(close)
        this.ctx.viewport(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.ctx.clear(this.ctx.COLOR_BUFFER_BIT);
    };

    /**
     * 关闭该merge
     * @param {*} close 
     * 是否同步关闭所有创建的流
     */
    JsVideoMergerWebgl.prototype.close = function (close) {
        this.stream.getTracks()[0].stop()
        this.ctx = null
        this.canvas = null
        this.lastTrack = null
        if (this.firstTrack != null) {
            this.firstTrack.disconnectAll(close)
        }
        this.firstTrack = null

    };

    return JsVideoMergerWebgl;
}());

function NewVideoMergeWebgl(canvas) {
    return new JsVideoMergerWebgl(canvas)
}

window.NewVideoMergeWebgl = NewVideoMergeWebgl