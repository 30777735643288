// require("hacktimer");
var JsVideoCodec = /** @class */ (function () {

    function JsVideoCodec(stream) {
        this.stream = stream
        this.encoder = null
        this.decoder = null
        this.frame_counter = 0
    }

    JsVideoCodec.prototype.GetCodecNames = function () {
        const CodecNames = [];

        //	https://stackoverflow.com/questions/16363167/html5-video-tag-codecs-attribute
        function IntToHexString(Integer) {
            return (Integer).toString(16).toUpperCase();
        }
        //	chromium constant names to aid googling
        const ProfileIntegers =
        {
            H264PROFILE_BASELINE: 66,
            H264PROFILE_MAIN: 77,
            H264PROFILE_SCALABLEBASELINE: 83,
            H264PROFILE_SCALABLEHIGH: 86,
            H264PROFILE_EXTENDED: 88,
            H264PROFILE_HIGH: 100,
            H264PROFILE_HIGH10PROFILE: 110,
            H264PROFILE_MULTIVIEWHIGH: 118,
            H264PROFILE_HIGH422PROFILE: 122,
            H264PROFILE_STEREOHIGH: 128,
            H264PROFILE_HIGH444PREDICTIVEPROFILE: 244,
        };

        const Profiles = Object.values(ProfileIntegers).map(IntToHexString).reverse();

        const Level30 = IntToHexString(30);	//	1E
        const Level31 = IntToHexString(31);	//	1F
        const Level40 = IntToHexString(40); //	28
        const Level41 = IntToHexString(41);	//	29
        const Level42 = IntToHexString(42);	//	2A
        const Constraints00 = '00';
        const Constraints01 = '01';
        const ConstraintsE0 = 'E0';
        for (let CodecName of ['avc1']) {
            for (let Profile of Profiles) {
                for (let Constraint of [Constraints00, Constraints01, ConstraintsE0]) {
                    for (let Level of [Level42, Level41, Level40, Level30, Level31]) {
                        const Codec = `${CodecName}.${Profile}${Constraint}${Level}`;
                        CodecNames.push(Codec);
                    }
                }
            }
        }
        return CodecNames;
    }

    JsVideoCodec.prototype.VideoEncoderGetSupport = async function (config) {
        const array = this.GetCodecNames()
        config.avc = {
            format: "avc"
        }
        // config.codec = "avc1.64002a"
        // const support = await VideoEncoder.isConfigSupported(config);
        // if(support.supported){
        //     return {
        //         supported: support.supported,
        //         config
        //     }
        // }
        for (const codec of array) {
            config.codec = codec
            try {
                const support = await VideoEncoder.isConfigSupported(config);
                if (support.supported) {
                    return {
                        supported: support.supported,
                        config
                    }
                }
            }catch(e){}
            
        }
        return { supported: false }

    }

    JsVideoCodec.prototype.VideoEncoder = async function (config, output, err) {
        if (this.encoder == null) {
            const track = this.stream.getVideoTracks()[0]
            const processor = new MediaStreamTrackProcessor(track);
            let videoRead = processor.readable
            const init = {
                output: output,
                error: (e) => {
                    err(e)
                }
            };
            this.encoder = new VideoEncoder(init);
            if (config.codec == undefined) {
                const supportCode = await this.VideoEncoderGetSupport(config)
                if (!supportCode.supported) {
                    err({
                        Code: 201,
                        Err: "not found support code"
                    })

                }
                config = supportCode.config
            }
            console.log(config)
            this.encoder.configure(config);
            const that = this;
            that.frame_counter = 0;
            const stream = new WritableStream({
                start() {
                },
                write(chunk) {
                    if (that.encoder != null && that.encoder.state != "closed") {
                        that.frame_counter++
                        const insert_keyframe = (that.frame_counter % 30) == 0;
                        if (insert_keyframe) {
                            that.frame_counter = 0;
                        }
                        that.encoder.encode(chunk, { keyFrame: insert_keyframe });
                    } else {
                        track.stop()
                    }
                    chunk.close()
                },
                close() {
                    stream.close()
                }
            });
            videoRead.pipeTo(stream)
        }

    };

    JsVideoCodec.prototype.VideoEncoderClose = async function () {
        if (this.encoder != null) {
            await this.encoder.flush();
            this.encoder.close()
            this.encoder = null
        }
    }

    JsVideoCodec.prototype.VideoDecoder = function (config, draw, err) {
        if (this.decoder != null) {
            return null;
        }
        const init = {
            output: (frame) => {
                draw(frame)
                frame.close()
            },
            error: (e) => {
                err(e)
            }
        };
        this.decoder = new VideoDecoder(init);
        if (config.codec == undefined) {
            config.codec = "avc1.64002a"
            config.avc = { format: "avc" }
        }
        this.decoder.configure(config);
        const setChunk = (chunk) => {
            if (this.decoder != null && this.decoder.state != "closed") {
                this.decoder.decode(chunk);
            }

        }
        const flushConfig = (decoderConfig) => {
            if (this.decoder != null && this.decoder.state != "closed") {
                this.decoder.configure(decoderConfig);
            }

        }
        return { setChunk, flushConfig }
    }

    JsVideoCodec.prototype.VideoDecoderClose = async function () {
        if (this.decoder != null) {
            await this.decoder.flush();
            this.decoder.close()
            this.decoder = null;
        }
    }

    JsVideoCodec.prototype.parseAVCC = function (avcc) {
        const view = new DataView(avcc);
        let off = 0;
        const version = view.getUint8(off++)
        const profile = view.getUint8(off++);
        const compat = view.getUint8(off++);
        const level = view.getUint8(off++);
        const length_size = (view.getUint8(off++) & 0x3) + 1;
        if (length_size !== 4) throw new Error('Expected length_size to indicate 4 bytes')
        const numSPS = view.getUint8(off++) & 0x1f;
        const sps_list = [];
        for (let i = 0; i < numSPS; i++) {
            const sps_len = view.getUint16(off, false);
            off += 2;
            const sps = new Uint8Array(view.buffer, off, sps_len);
            sps_list.push(sps);
            off += sps_len;
        }
        const numPPS = view.getUint8(off++);
        const pps_list = [];
        for (let i = 0; i < numPPS; i++) {
            const pps_len = view.getUint16(off, false);
            off += 2;
            const pps = new Uint8Array(view.buffer, off, pps_len);
            pps_list.push(pps)
            off += pps_len;
        }
        return {
            offset: off,
            version,
            profile,
            compat,
            level,
            length_size,
            pps_list,
            sps_list,
            numSPS
        }
    }

    return JsVideoCodec;
}());

function NewVideoCodec(stream) {
    return new JsVideoCodec(stream)
}

window.NewVideoCodec = NewVideoCodec