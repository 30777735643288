import Stream from "./stream";
import UserOpera from "./userOpera";
import RemoteStream from "./remoteStream";

export default class YytConnect {

    constructor(id) {
        this.id = id
        this.autoReRtc = false
        this.autoReSocket = false
        this.pingTimer = null
        this.pingServerTimer = null
        this.url = window.YyttSdkUrl
        this.wsUrl = window.YyttSdkWsUrl
        this.msgCacheMap = new Map()
        this.userOpera = new UserOpera(this)
        this.remoteStream = new RemoteStream(this)

        this.localStreams = new Map()
    }

    onErr(e) {

    }

    onRtcErr() {

    }

    open() {

    }

    onClose() {

    }

    busMessage(busMsg) {

    }

    message(tagMsg) {

    }

    guid() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }


    async connect() {
        console.log('SDK---创建连接')
        const promise = new Promise(async (resolve, reject) => {
            const localConnection = new RTCPeerConnection();
            localConnection.googCpuLimitedResolution = true
            localConnection.addTransceiver("video", {
                direction: "recvonly"
            });
            localConnection.addTransceiver("audio", {
                direction: "recvonly"
            });
            this.localConnection = localConnection
            localConnection.onconnectionstatechange = (event) => {
                console.error('-----localConnection.connectionState:', localConnection.connectionState)
                switch(localConnection.connectionState) {
                    case "connected":
                        // this.reLocalStreamPush()
                        if (this.open) {
                            this.open()
                        }
                        resolve()
                        // this.listen()
                        break;
                    // case "closed":
                    case "disconnected":
                        if(this.onRtcErr) {
                            this.onRtcErr()
                        }
                        // if (this.onClose) {
                        //     this.onClose()
                        // }
                        reject('localConnection:disconnected')
                        break;
                    case "failed":
                        console.log('sdk----：webrtc连接failed')
                        reject('localConnection:failed')
                        break;

                }
            };
            const ch = localConnection.createDataChannel("yytserver"+this.id);
            ch.onclose = () => {
                console.error("DataChannel closed")
            }
            localConnection.ontrack = (e) => {
                this.remoteStream._ontrack(e)
            };
            const offer = await this.getOffer()
            this._connect(offer, reject)
        })

        return promise
    }

    async getOffer() {
        const offer = await this.localConnection.createOffer()
        await this.localConnection.setLocalDescription(offer)
        return offer
    }

    async setSdp(des) {
        return this.localConnection.setRemoteDescription(des)
    }

    createMsgTags(methodName, params, callback) {
        const uuid = this.guid();
        this.msgCacheMap.set(uuid, callback)
        const data = this.Tags(uuid, methodName, params)
        this.sendData(data)
    }

    createMsgUserMethod(methodName, params, callback) {
        const uuid = this.guid();
        this.msgCacheMap.set(uuid, callback)
        const data = this.UserMethod(uuid, methodName, params)
        this.sendData(data)
    }

    createMsgWebrtcMethod(methodName, params, callback) {
        const uuid = this.guid();
        this.msgCacheMap.set(uuid, callback)
        const data = this.webrtcMethod(uuid, methodName, params)
        this.sendData(data)
    }

    createMsgWebCodecs(methodName, params, callback) {
        const uuid = this.guid();
        this.msgCacheMap.set(uuid, callback)
        const data = this.webCodecsMethod(uuid, methodName, params)
        this.sendData(data)
    }

    sendData(data){
        if(this.sendChannel.readyState == 1){
            this.sendChannel.send(data)
        }
    }

    registerLocalStream(stream) {
        this.localStreams.set(stream.Id(), stream)
    }

    unRegisterLocalStream(stream) {
        this.localStreams.delete(stream.Id())
    }

    listen() {
        const promise = new Promise((resolve, reject) => {
            const ws = new WebSocket(this.wsUrl+"?uid="+this.id)
            ws.onopen = () => {
                console.log('SDK---websocket连接成功')
                this.autoReSocket = true
                this.sendChannel = ws
                this.heartCheckReset()
                this.heartCheckStart()
                resolve()
            }
            ws.onmessage = (e) => {
                // const data = String.fromCharCode.apply(null, new Uint8Array(e.data));
                // const decodedString = decodeURIComponent(escape(data));

                this.heartCheckReset()
                this.heartCheckStart()
                // 心跳
                if(e.data === 'pong') {
                    return
                }

                const parse = JSON.parse(e.data);
                if (window.debug) {
                    console.log(parse)
                }
                if (parse.msgType.toString() === "2") {
                    const callback = this.msgCacheMap.get(parse.msgId.toString())
                    if (callback != null) {
                        this.msgCacheMap.delete(parse.msgId.toString())
                        callback(parse.data)
                    }
                    return
                }
                if (parse.msgType.toString() === "1") {
                    if (parse.data.source.toString() === "webrtcEngine") {
                        if (parse.data.data.info == 8) {
                            const stream = this.localStreams.get(parse.data.data.stream.id)
                            if (stream != null) {
                                stream.message(parse.data.data)
                            }
                        } else {
                            this.remoteStream.message(parse.data.data)
                        }
                    }
                    if (parse.data.source.toString() === "tag") {
                        this.message(parse.data.data)
                    }
                    return
                }
                if (parse.msgType.toString() === "3") {
                    this.busMessage(parse.data)
                    return
                }
            }
            ws.onclose = (e) =>  {
                //断开连接，重新进行连接
                console.error('SDK--websoket断开连接：是否重连', this.autoReSocket)
                if(this.autoReSocket) {
                    let timer = setTimeout(() => {
                        clearTimeout(timer)
                        this.listen()
                    }, 1000)
                }
                // if(this.localConnection != null && this.localConnection.connectionState == "connected"){
                //     this.listen()
                // }
            }
            ws.onerror = (e) => {
                console.error('SDK--websoket连接异常')
                reject()
                if (this.onErr) {
                    this.onErr(e)
                }
            }
        })

        return promise
    }

    _connect(desc, reject) {
        const url = this.url;
        const httpRequest = new XMLHttpRequest();
        httpRequest.open('POST', url, true);
        httpRequest.setRequestHeader("Content-type", "application/json");
        const obj = {
            "id": this.id,
            "sdp": JSON.stringify(desc),
            "ws": "true"
        };
        console.log('SDK-----创建http连接', obj)
        httpRequest.send(JSON.stringify(obj));
        httpRequest.onreadystatechange = () => {
            if (httpRequest.status == 200 && httpRequest.readyState == 4) {
                const json = httpRequest.responseText;
                const obj = JSON.parse(json);
                if (obj.code === "200") {
                    const sdp = JSON.parse(obj.data);
                    this.setSdp(sdp).then(() => {

                    }).catch(e => {
                        this.onErr(new Error(obj))
                    })
                } else {
                    this.onErr(new Error(obj))
                }
            } else if(httpRequest.status != 200) {
                reject(httpRequest)
                this.onErr(new Error(('httpRequest.status: '+httpRequest.status)))
            }
        };
    }

    close() {
        if(this.localConnection) {
            this.localConnection.close()
        }
        this.localConnection = null
        if(this.sendChannel) {
            this.sendChannel.close()
        }
        this.sendChannel = null
        this.autoReSocket = false
        this.autoReRtc = false
        if (this.onClose) {
            this.onClose()
        }
    }

    closeWebRtc() {
        if(this.localConnection) {
            this.localConnection.close()
        }
        this.autoReRtc = false
        this.localConnection = null
    }

    heartCheckStart() {
        var self = this
        this.pingTimer = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            self.sendChannel.send("ping")
            self.pingServerTimer = setTimeout(function () { //如果超过一定时间还没重置，说明后端主动断开了
                console.error('心跳未重置，主动断开了！')
                self.sendChannel.close()
            },5000)
        }, 40000)

    }
    heartCheckReset() {
        clearTimeout(this.pingTimer)
        clearTimeout(this.pingServerTimer)
    }


    createStream(streamName) {
        const stream = new Stream(this, streamName)
        this.registerLocalStream(stream)
        return stream
    }

    UserOpera() {
        return this.userOpera
    }

    RemoteStream() {
        return this.remoteStream
    }

    Tags(uuid, methodName, params) {
        const times = new Date().Format("yyyy-MM-dd HH:mm:ss");
        let datas = {
            "data": {
                "clazz": "Tags",
                "method": methodName,
                "params": params,
                "time": times,
                "timeOut": 10
            },
            "msgId": uuid,
            "msgType": 2,
            "time": times,
            "version": 0
        };
        return JSON.stringify(datas)
    }

    UserMethod(uuid, methodName, params) {
        const times = new Date().Format("yyyy-MM-dd HH:mm:ss");
        let datas = {
            "data": {
                "clazz": "UserMethod",
                "method": methodName,
                "params": params,
                "time": times,
                "timeOut": 10
            },
            "msgId": uuid,
            "msgType": 2,
            "time": times,
            "version": 0
        };
        return JSON.stringify(datas)
    }

    webrtcMethod(uuid, methodName, params) {
        const times = new Date().Format("yyyy-MM-dd HH:mm:ss");
        const datas = {
            "data": {
                "clazz": "WebrtcMethod",
                "method": methodName,
                "params": params,
                "time": times,
                "timeOut": 10
            },
            "msgId": uuid,
            "msgType": 2,
            "time": times,
            "version": 0
        };
        return JSON.stringify(datas)
    }

    webCodecsMethod(uuid, methodName, params) {
        const times = new Date().Format("yyyy-MM-dd HH:mm:ss");
        const datas = {
            "data": {
                "clazz": "ReportEngine",
                "method": methodName,
                "params": params,
                "time": times,
                "timeOut": 10
            },
            "msgId": uuid,
            "msgType": 2,
            "time": times,
            "version": 0
        };
        return JSON.stringify(datas)
    }
}
