var JsAudioCodec = /** @class */ (function () {
    /**
     * Creates an instance of JsAudioMerger.
     * @memberof JsAudioMerger
     */
    function JsAudioCodec(stream) {
        this.stream = stream
        this.encoder = null
        this.decoder = null
    }

   
    JsAudioCodec.prototype.AudioEncoder = function (config, output, err) {
        if (this.encoder == null) {
            const track = this.stream.getAudioTracks()[0]
            const processor = new MediaStreamTrackProcessor(track);
            let audioRead = processor.readable
            const init = {
                output: output,
                error: (e) => {
                    err(e)
                }
            };
            this.encoder = new AudioEncoder(init);
            if (config == null) {
                config = {
                    codec: "mp4a.40.02",
                    sampleRate: 48000,
                    numberOfChannels: 2,
                };
            }
            this.encoder.configure(config);
            const that = this;
            const stream = new WritableStream({
                start() {
                },
                write(chunk) {
                    if (that.encoder != null && that.encoder.state != "closed") {
                        that.encoder.encode(chunk);
                    } else {
                        track.stop()
                    }
                    chunk.close()
                },
                close() {
                    stream.close()
                }
            });
            audioRead.pipeTo(stream)
        }

    };

    JsAudioCodec.prototype.AudioEncoderClose = async function () {
        if (this.encoder != null) {
            await this.encoder.flush()
            this.encoder.close()
            this.encoder = null
        }
    }

    JsAudioCodec.prototype.AudioDecoder = function (config, output, err) {
        if (this.decoder == null) {
            const init = {
                output: (frame) => {
                    output(frame)
                    frame.close()
                },
                error: (e) => {
                    err(e)
                }
            };
            this.decoder = new AudioDecoder(init);
            if (config == null) {
                config = {
                    codec: "mp4a.40.02",
                    sampleRate: 48000,
                    numberOfChannels: 2,
                };
            }
            this.decoder.configure(config);
            const setChunk = (chunk) => {
                if (this.decoder != null && this.decoder.state != "closed") {
                    this.decoder.decode(chunk);
                }

            }
            return setChunk
        }
    }

    JsAudioCodec.prototype.AudioDecoderClose = async function () {
        if (this.decoder != null) {
            await this.decoder.flush()
            this.decoder.close()
            this.decoder = null;
        }
    }

    JsAudioCodec.prototype.parseAVCC = function (avcc) {
        const view = new DataView(avcc);
        let off = 0;
        const version = view.getUint8(off++)
        const profile = view.getUint8(off++);
        const compat = view.getUint8(off++);
        const level = view.getUint8(off++);
        const length_size = (view.getUint8(off++) & 0x3) + 1;
        if (length_size !== 4) throw new Error('Expected length_size to indicate 4 bytes')
        const numSPS = view.getUint8(off++) & 0x1f;
        const sps_list = [];
        for (let i = 0; i < numSPS; i++) {
            const sps_len = view.getUint16(off, false);
            off += 2;
            const sps = new Uint8Array(view.buffer, off, sps_len);
            sps_list.push(sps);
            off += sps_len;
        }
        const numPPS = view.getUint8(off++);
        const pps_list = [];
        for (let i = 0; i < numPPS; i++) {
            const pps_len = view.getUint16(off, false);
            off += 2;
            const pps = new Uint8Array(view.buffer, off, pps_len);
            pps_list.push(pps)
            off += pps_len;
        }
        return {
            offset: off,
            version,
            profile,
            compat,
            level,
            length_size,
            pps_list,
            sps_list,
            numSPS
        }
    }


   
    return JsAudioCodec;
}());

function NewAudioCodec(stream) {
    return new JsAudioCodec(stream)
}

window.NewAudioCodec = NewAudioCodec