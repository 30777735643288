
import YytConnect from './yytConnect';
import WsPushStream from './wsPushStream';

function NewConnect(id){
    return new YytConnect(id)
}

function NewWsPushStream(config) {
    return new WsPushStream(config)
}

Date.prototype.Format = function(fmt)
{ 
    var o = {
        "M+" : this.getMonth()+1, //月份
        "d+" : this.getDate(), //日
        "h+" : this.getHours()%12 == 0 ? 12 : this.getHours()%12, //小时
        "H+" : this.getHours(), //小时
        "m+" : this.getMinutes(), //分
        "s+" : this.getSeconds(), //秒
        "q+" : Math.floor((this.getMonth()+3)/3), //季度
        "S" : this.getMilliseconds() //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
} 
window.YyttSdkUrl = "http://159.75.43.185:8702/rtc/connect"
window.YyttSdkWsUrl = "ws://159.75.43.185:8702/rtc/connect/ws"
window.NewConnect = NewConnect
window.NewWsPushStream = NewWsPushStream