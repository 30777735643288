import JsVideoTrack from './videoTrack';
require("hacktimer");
var JsVideoMerger = /** @class */ (function () {

    function JsVideoMerger(canvas) {
        this.canvas = canvas
        this.ctx = this.canvas.getContext('2d')
        this.ratio = 1;
        this.ctx.scale(this.ratio, this.ratio)
        this.stream = this.canvas.captureStream(30)
        this.lastTrack = null
        this.firstTrack = null
        this.intervalId = null
    }

    /**
     *  从MediaStream获取视频流
     * @param {*} stream 
     * @param  {...any} position 
     * @returns 
     */
    JsVideoMerger.prototype.addFromStream = function (stream, ...position) {
        if (!stream.getVideoTracks().length) {
            throw new Error('The stream does not have any video tracks');
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        const track = new JsVideoTrack(this, this.ctx);
        track.createFromStream(this.ratio, stream, ...position)
        if (this.firstTrack == null) {
            track.initPre(null)
            track.initNext(null)
            this.firstTrack = track
            this.lastTrack = track
            this.draw(this)
        } else {
            track.initPre(this.lastTrack)
            this.lastTrack.initNext(track)
            this.lastTrack = track
        }
        return track
    };

    /**
     *  从MediaElement标签（audio，video等）获取
     * @param {*} stream 
     * @param  {...any} position 
     * @returns 
     */
    JsVideoMerger.prototype.addFromElement = function (el, ...position) {
        const stream = el.captureStream();
        if (stream == null || !stream.getVideoTracks().length) {
            throw new Error('The stream does not have any video tracks');
        }
        return this.addFromStream(stream, ...position)
    };

    /**
     * 获取合成的视频流
     * @returns 
     */
    JsVideoMerger.prototype.getStream = function () {
        return this.stream
    };

    JsVideoMerger.prototype.draw = (that) => {
        if (that.intervalId != null) {
            window.clearInterval(that.intervalId);
        }
        const anima = () => {
            if (that.canvas != null) {
                if (that.firstTrack != null) {
                    // that.ctx.clearRect(0, 0, that.canvas.width, that.canvas.height); 
                    that.firstTrack.draw(that.ctx)
                    return
                }
            }
        }
        that.intervalId = window.setInterval(anima, 1000 / 30);

    };

    /**
     * 移除视频流
     * @param {} track 
     * @param {*} close 
     * 是否关闭创建的视频流
     */
    JsVideoMerger.prototype.removeTrack = function (track, close) {
        if (this.firstTrack == track) {
            this.firstTrack = track.nextTrack
        }
        if (this.lastTrack == track) {
            this.lastTrack = track.preTrack
        }
        const preTrack = track.preTrack
        const nextTrack = track.nextTrack
        if (preTrack != null) {
            preTrack.initNext(nextTrack)
        }
        if (nextTrack != null) {
            nextTrack.initPre(preTrack)
        }
        track.disconnect(close)
        // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    };

    /**
     * 关闭该merge
     * @param {*} close 
     * 是否同步关闭所有创建的流
     */
    JsVideoMerger.prototype.close = function (close) {
        this.stream.getTracks()[0].stop()
        this.ctx = null
        this.canvas = null
        this.lastTrack = null
        if (this.firstTrack != null) {
            this.firstTrack.disconnectAll(close)
        }
        this.firstTrack = null

    };

    return JsVideoMerger;
}());

function NewVideoMerge(canvas) {
    return new JsVideoMerger(canvas)
}

window.NewVideoMerge = NewVideoMerge