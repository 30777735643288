import cbor from './cbor';

export default class WsPushStream {

    constructor(config) {
        this.videoList = []
        this.audioList = []
        this.videoAvcc = null
        this.audioAvcc = null
        this.clock = {
            pts: -1,
            videoPts: -1,
            lastAudioTime: 0,
            lastVideoTime: 0,
        }
        if (config == undefined) {
            config = {}
        }
        if (config.cacheTime == undefined) {
            config.cacheTime = 1000
        }
        if (config.sendVideo == undefined) {
            config.sendVideo = true
        }
        if (config.sendAudio == undefined) {
            config.sendAudio = true
        }
        this.config = config
        this.streamId = ""
        this.url = ""
        this.start = false
        this.canStart = true
    }

    AddVideo(chunk, opts) {
        if (!this.config.sendVideo) {
            return
        }
        let data = null
        const { type, timestamp, duration } = chunk;
        if (opts.decoderConfig != null) {
            const avcc = new Uint8Array(opts.decoderConfig.description);
            const head = new Uint8Array(5)
            head[0] = 23
            head[1] = 0
            head[2] = 0
            head[3] = 0
            head[4] = 0
            let kind = 2;
            data = {
                kind,
                type: 1,
                timestamp: 0,
                duration,
                avcc: avcc,
                head: head
            }
            this.videoAvcc = data
        }
        if (this.videoList.length == 0) {
            this._addVideoData(chunk, opts)
        } else {
            const firstData = this.videoList[0]
            const ts = parseInt((timestamp - firstData.timestamp) / 1000)
            //判断新的视频数据和第一个视频数据的时间差值，如果差值大于设置的缓存，则直接丢弃第一个数据
            if (ts > this.config.cacheTime) {
                //丢弃视频数据
                const videoData = this.videoList.shift()
                this.clock.lastVideoTime = videoData.timestamp
            }
            this._addVideoData(chunk, opts)
        }
    }

    _addVideoData(chunk, opts) {
        const { type, timestamp, duration } = chunk;
        let dataByte = new Uint8Array(chunk.byteLength);
        chunk.copyTo(dataByte.buffer);
        let kind = 2;
        let head = null;
        let key = 0;
        if (type == "key") {
            key = 0
            head = new Uint8Array(5)
            head[0] = 23
            head[1] = 1
            head[2] = 0
            head[3] = 0
            head[4] = 0
        } else {
            key = 1
            head = new Uint8Array(5)
            head[0] = 39
            head[1] = 1
            head[2] = 0
            head[3] = 0
            head[4] = 0
        }
        const data = {
            kind,
            type: key,
            timestamp: timestamp,
            duration,
            data: dataByte,
            head: head
        }
        this.videoList.push(data)
        if (this.start && !this.config.sendAudio) {
            this._pushVideo()
        }
    }

    AddAudio(chunk, opts) {
        if (!this.config.sendAudio) {
            return
        }
        let data = null
        const { type, timestamp, duration } = chunk;
        if (opts.decoderConfig != null) {
            // const avcc = new Uint8Array(opts.decoderConfig.description);
            const avcc = new Uint8Array();
            const head = new Uint8Array(4);
            head[0] = 175
            head[1] = 0
            head[2] = 17
            head[3] = 144
            let kind = 1;
            data = {
                kind,
                type: 0,
                timestamp: timestamp,
                duration,
                avcc: avcc,
                head: head
            }
            this.audioAvcc = data
        }
        if (this.audioList.length == 0) {
            this._addAudioData(chunk, opts)
        } else {
            const firstData = this.audioList[0]
            const ts = parseInt((timestamp - firstData.timestamp) / 1000)
            //判断新的音频数据和第一个音频数据的时间差值，如果差值大于设置的缓存，则直接丢弃第一个音频数据
            if (ts > this.config.cacheTime) {
                //丢弃音频数据
                const audioData = this.audioList.shift()
                this.clock.lastAudioTime = audioData.timestamp
            }
            this._addAudioData(chunk, opts)
        }

    }

    _addAudioData(chunk, opts) {
        const { type, timestamp, duration } = chunk;
        let dataByte = new Uint8Array(chunk.byteLength);
        chunk.copyTo(dataByte.buffer);
        let kind = 1;
        const head = new Uint8Array(2);
        head[0] = 175
        head[1] = 1
        const data = {
            kind,
            type: 0,
            timestamp: timestamp,
            duration,
            data: dataByte,
            head: head
        }
        this.audioList.push(data)
        if (this.start) {
            this._pushAudio()
        }
    }

    async Push(url, streamId, resolve, reject) {
        if (!this.canStart) {
            reject({
                Code: 201,
                Err: "already start"
            })
            return
        }
        this.canStart = false
        let videoTrue = (this.videoList.length > 3 && this.videoAvcc != null) && this.config.sendVideo
        let audioTrue = (this.audioList.length > 3 && this.audioAvcc != null) && this.config.sendAudio
        if (audioTrue && videoTrue) {
            this.url = url
            this.streamId = streamId
            this.resolve = resolve
            this.reject = reject
            this._pushConnect()
            return
        } else {
            await this.sleep(10)
            this.canStart = true
            return this.Push(url, streamId, resolve, reject)
        }
    }

    sleep(time) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    StopPush() {
        return new Promise((resolve, reject) => {
            this.start = false
            this.canStart = true
            if (this.ws != null && this.ws.readyState == this.ws.OPEN) {
                this.ws.onclose = (e) => {
                    this.ws = null
                    resolve({
                        Code: 200,
                        Err: "success"
                    })
                }
                this.ws.close()
            } else {
                reject({
                    Code: 201,
                    Err: "not push stream"
                })
            }
        })

    }

    _pushAudio() {
        const pushAudio = this.audioList.shift()
        //如果pts等于0，则表示第一帧数据
        if (this.clock.pts == -1) {
            this.clock.pts = 0
            this.clock.lastAudioTime = pushAudio.timestamp
        } else {
            //计算需要发送的音频数据和上一个发送音频数据的的时间差，将pts进行累加
            const ts = parseInt((pushAudio.timestamp - this.clock.lastAudioTime) / 1000)
            this.clock.pts += ts
            this.clock.lastAudioTime = pushAudio.timestamp
        }
        this._sendData({ ...pushAudio, timestamp: this.clock.pts }, 1)
        this._pushVideo()
    }

    _pushVideo() {
        const pushVideo = this.videoList[0]
        if (pushVideo == undefined) {
            return
        }
        if (this.clock.videoPts == -1) {
            this.clock.videoPts = 0
            this.clock.lastVideoTime = pushVideo.timestamp
            this._sendData({ ...pushVideo, timestamp: this.clock.videoPts }, 2)
            this.videoList.shift()
        } else {
            const ts = parseInt((pushVideo.timestamp - this.clock.lastVideoTime) / 1000)
            if (!this.config.sendAudio) {
                this.clock.videoPts += ts
                this.clock.lastVideoTime = pushVideo.timestamp
                this._sendData({ ...pushVideo, timestamp: this.clock.videoPts }, 2)
                this.videoList.shift()
            } else {
                if (ts == 0) {
                    this.clock.videoPts += ts
                    this.clock.lastVideoTime = pushVideo.timestamp
                    this._sendData({ ...pushVideo, timestamp: this.clock.videoPts }, 2)
                    this.videoList.shift()
                } else if (ts + this.clock.videoPts < this.clock.pts) {
                    this.clock.videoPts += ts
                    this.clock.lastVideoTime = pushVideo.timestamp
                    this._sendData({ ...pushVideo, timestamp: this.clock.videoPts }, 2)
                    this.videoList.shift()
                }
            }
        }
    }

    tscreate(time) {
        const one = time >> 24
        const two = time >> 16
        const three = time >> 8
        const four = time & 255
        return [one, two, three, four]
    }

    _sendData(data, kind) {
        if (this.ws != null && this.ws.readyState == this.ws.OPEN) {
            if (data.avcc != null) {
                const ts = new Uint8Array(4)
                const time = this.tscreate(data.timestamp)
                ts.set([...time])
                const sendData = new Uint8Array([kind, data.type, 0, ...ts, ...data.head, ...data.avcc])
                this.ws.send(sendData)
            }
            if (data.data != null) {
                const ts = new Uint8Array(4)
                const time = this.tscreate(data.timestamp)
                ts.set([...time])
                const sendData = new Uint8Array([kind, data.type, 1, ...ts, ...data.head, ...data.data])
                this.ws.send(sendData)
            }
        }
    }

    Connect() {
        return this._connect()
    }

    _connect() {
        const promise = new Promise((resolve, reject) => {
            this.ws = new WebSocket(this.url + "?id=" + this.streamId)
            this.ws.id = new Date()
            const that = this.ws
            this.ws.onopen = () => {
                resolve()
            }
            this.ws.onmessage = async (event) => {
                const msg = await event.data.arrayBuffer()
                const data = cbor.decode(msg)
                if (data.Code == 200) {
                    this.resolve(data)
                } else {
                    this.reject(data)
                }
            }
            this.ws.onclose = (e) => {
                this.start = false
                this.canStart = true
                this.ws = null
                this.reject({
                    Code: 201,
                    Err: e
                })
            }
            this.ws.onerror = (e) => {
                this.start = false
                this.canStart = true
                this.ws = null
                reject({
                    Code: 201,
                    Err: e
                })
            }
        })
        return promise
    }

    _pushAvcc() {
        this._sendData({ ...this.audioAvcc, timestamp: 0 }, 1)
        this._sendData({ ...this.videoAvcc, timestamp: 0 }, 2)
        if (this.ws != null && this.ws.readyState == this.ws.OPEN) {
            //因为如果当前时间太久没有开始发送数据，后台会自动断开该websocket,避免多余资源占用
            //此处判断该流是否还在允许推送
            this.start = true
        }
    }


    _pushConnect() {
        this.clock.pts = -1
        this.clock.videoPts = -1
        this.clock.lastAudioTime = 0
        this.clock.lastVideoTime = 0
        this.Connect().then(() => {
            this._pushAvcc()
        }).catch(e => {
            this.reject({
                Code: 201,
                Err: e
            })
        })
    }
}

