import Track from './audioTrack';
var JsAudioMerger = /** @class */ (function () {
    /**
     * Creates an instance of JsAudioMerger.
     * @memberof JsAudioMerger
     */
    function JsAudioMerger(config) {
        this.audioCtx = new AudioContext(config);
        this.source = this.audioCtx.createMediaStreamDestination()
        this.tracks = new Map()
    }

    JsAudioMerger.prototype.getAudioContext = function () {
        return this.audioCtx;
    };

    //从MediaStream获取音频流
    JsAudioMerger.prototype.addFromStream = function (stream) {
        if (!stream.getAudioTracks().length) {
            throw new Error('The stream does not have any audio tracks');
        }
        var track = new Track(this.audioCtx);
        track.createFromStream(stream);
        track.connect(this.source)
        this.tracks.set(track.Id(), track)
        return track;
    };

    //从MediaElement标签（audio，video等）获取音频流
    JsAudioMerger.prototype.addFromElement = function (mediaElement) {
        var track = new Track(this.audioCtx);
        track.createFromElement(mediaElement);
        track.connect(this.source)
        this.tracks.set(track.Id(), track)
        return track;
    };

    //获取合成的音频流
    JsAudioMerger.prototype.getStream = function () {
        return this.source.stream;
    };

    /**
     * 克隆source
     * @returns 
     */
    JsAudioMerger.prototype.cloneSource = function () {
        const source = this.audioCtx.createMediaStreamDestination()
        this.tracks.forEach((track, key) => {
            track.connect(source)
        });
        return source;
    };

    /**
     * 移除音频流
     * @param {y} track 
     * 是否关闭创建的音频流。
     * @param {*} close 
     */
    JsAudioMerger.prototype.removeTrack = function (track, close) {
        this.tracks.delete(track.Id())
        track.disconnect(this.source, close)
    };

    /**
     * 移除音频流
     * @param {y} track 
     * 是否关闭创建的音频流。
     * @param {*} close 
     */
    JsAudioMerger.prototype.removeTrackInSource = function (source, track, close) {
        this.tracks.delete(track.Id())
        track.disconnect(source, close)
    };

    /**
     * 销毁关闭该Merge
     */
    JsAudioMerger.prototype.close = function () {
        this.audioCtx.close();
    };

    return JsAudioMerger;
}());

function NewAudioMerge(config) {
    return new JsAudioMerger(config)
}

window.NewAudioMerge = NewAudioMerge