
var JsVideoTrack = /** @class */ (function () {

    function JsVideoTrack(videoMerge, ctx) {
        this.ctx = ctx
        this.videoRead = null
        this.track = null
        this.videoMerge = videoMerge
        this.stream = null
        this.emptyNum = 0
        this.newVideoFrame = null
    }

    JsVideoTrack.prototype.initPre = function (preTrack) {
        this.preTrack = preTrack
    };

    JsVideoTrack.prototype.initNext = function (nextTrack) {
        this.nextTrack = nextTrack
    };


    JsVideoTrack.prototype.createFromStream = function (ratio, stream, ...position) {
        this.ratio = ratio
        this.positionSet(position)
        this.stream = stream
        this.track = stream.getVideoTracks()[0].clone()
        if (this.track.readyState == "live") {
            const processor = new MediaStreamTrackProcessor(this.track);
            this.videoRead = processor.readable
            this.loop()
        } else {
            //主流已经被销毁，移除该流
            this.videoMerge.removeTrack(this, false)
        }
    };

    JsVideoTrack.prototype.loop = function () {
        const that = this
        const stream = new WritableStream({
            start() {
            },
            write(chunk) {
                if (that.newVideoFrame != null) {
                    const videoFrame = that.newVideoFrame
                    videoFrame.close()
                }
                that.newVideoFrame = chunk
            },
            close() {
                if (that.newVideoFrame != null) {
                    that.newVideoFrame.close()
                }
                that.newVideoFrame = null
            }
        });
        this.videoRead.pipeTo(stream)
    };

    JsVideoTrack.prototype.disconnect = function (close) {
        if (this.track != null) {
            this.track.stop()
        }
        if (close && this.stream != null) {
            this.stream.getVideoTracks()[0].stop()
        }
        this.ctx = null
    };

    JsVideoTrack.prototype.disconnectAll = function (close) {
        this.disconnect(close)
        if (this.nextTrack != null) {
            this.nextTrack.disconnectAll(close)
        }
        this.ctx = null
    };

    JsVideoTrack.prototype.positionSet = function (position) {
        this.position = position
    }

    JsVideoTrack.prototype.changePosition = function (...position) {
        this.positionSet(position)
    }

    JsVideoTrack.prototype.draw = function (ctx) {
        if (this.newVideoFrame != null) {
            const videoFrame = this.newVideoFrame
            if (this.position == null || this.position == undefined) {
                this.positionSet(0, 0, this.videoMerge.canvas.width, this.videoMerge.canvas.height)
            }
            // const canvasWidth = this.ctx.canvas.width
            // const canvasHeight = this.ctx.canvas.height
            // const showWidth = this.position[2] ? this.position[2] : this.ctx.canvas.width
            // const showHeight = this.position[3] ? this.position[3] : this.ctx.canvas.height
            // const dWidth = showWidth - this.position[0]
            // const dHeight = showHeight - this.position[1]
            // const imgScaleWidth = dWidth / canvasWidth
            // const imgScaleHeight = dHeight / canvasHeight
            // const width = showWidth*imgScaleWidth
            // const height = showHeight*imgScaleHeight

            const imgScale = videoFrame.displayWidth / videoFrame.displayHeight;
            const width = videoFrame.displayWidth * this.ratio;
            const height = width / imgScale;
            ctx.drawImage(videoFrame, 0, 0, width, height
                , ...this.position)
            // ctx.drawImage(videoFrame, ...this.position)

        } else {
            this.emptyNum++
            if (this.emptyNum == 10) {
                this.track.stop()
                this.createFromStream(this.ratio, this.stream, ...this.position)
                this.emptyNum = 0
            }
        }
        if (this.nextTrack != null) {
            return this.nextTrack.draw(ctx)
        }
        return true
    }

    return JsVideoTrack;
}())

export default JsVideoTrack;