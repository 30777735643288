


export default class Stream {

    constructor(connect, streamName) {
        this.connect = connect
        this.mediaStream = new MediaStream();
        this.audioSender = null
        this.videoSender = null
        if (streamName === undefined) {
            this.streamName = this.mediaStream.id
        } else {
            this.streamName = streamName
        }

        this.resolve = null
        this.reject = null
        this.rtcTransceiver = null

    }

    Id() {
        return this.mediaStream.id
    }

    Name() {
        return this.streamName
    }

    MediaStream() {
        return this.mediaStream
    }

    changeVideoStatus(closeOrOpen) {
        if (this.VideoTrack() != null) {
            let methodName = ""
            if (!closeOrOpen) {
                methodName = "PauseStreamToUser"
                this.VideoTrack().enabled = false
            } else {
                methodName = "ResumeStreamToUser"
                this.VideoTrack().enabled = true
            }
            const params = {
                "0": this.Id(),
                "1": "video",
                "2": "this"
            }
            this.connect.createMsgWebrtcMethod(methodName, params, (result) => { })
        }
    }

    AudioTrack(){
        return this.MediaStream().getAudioTracks()[0]
    }

    VideoTrack(){
        return this.MediaStream().getVideoTracks()[0]
    }

    changeAudioStatus(closeOrOpen) {
        if (this.AudioTrack() != null) {
            let methodName = ""
            if (!closeOrOpen) {
                methodName = "PauseStreamToUser"
                this.AudioTrack().enabled = false
            } else {
                methodName = "ResumeStreamToUser"
                this.AudioTrack().enabled = true
            }
            const params = {
                "0": this.Id(),
                "1": "audio",
                "2": "this"
            }
            this.connect.createMsgWebrtcMethod(methodName, params, (result) => { })
        }
    }

    addAudioStream(stream) {
        const that = this
        if (that.AudioTrack() == null) {
            const tracks = stream.getTracks()
            tracks.forEach((track) => {
                if (track.kind == "audio") {
                    that.mediaStream.addTrack(track.clone())
                }
            });
        }
    }

    addVideoStream(stream) {
        const that = this
        if (that.VideoTrack() == null) {
            const tracks = stream.getTracks()
            tracks.forEach((track) => {
                if (track.kind == "video") {
                    that.mediaStream.addTrack(track.clone())
                }
            });
        }
    }

    replaceVideoTrack(track) {
        const oldTrack = this.mediaStream.getVideoTracks()[0]
        if(oldTrack != null){
            this.mediaStream.removeTrack(oldTrack)
        }
        if(track != null){
            this.mediaStream.addTrack(track)
        }
        if (this.videoSender != null) {
            this.videoSender.replaceTrack(track)
        }
        return oldTrack
    }

    replaceAudioTrack(track) {
        const oldTrack = this.mediaStream.getAudioTracks()[0]
        if(oldTrack != null){
            this.mediaStream.removeTrack(oldTrack)
        }
        if(track != null){
            this.mediaStream.addTrack(track)
        }
        if (this.audioSender != null) {
            this.audioSender.replaceTrack(track)
        }
        return oldTrack
    }

    createAudio() {
        const that = this
        const promise = new Promise((resolve, reject) => {
            if (that.AudioTrack() == null) {
                const constraints = {
                    video: false,
                    audio: true
                };
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((sendStream) => {
                        let audioTrack = null
                        sendStream.getTracks().forEach((track) => {
                            if (track.kind == "audio") {
                                audioTrack = track
                            }
                        });
                        sendStream.removeTrack(audioTrack)
                        that.mediaStream.addTrack(audioTrack)
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    });
            } else {
                reject()
            }
        })
        return promise

    }

    createVideo(width, height, fps) {
        const that = this
        const promise = new Promise((resolve, reject) => {
            if (that.VideoTrack() == null) {
                const constraints = {
                    video: { width: { exact: width }, height: { exact: height }, frameRate: fps },
                    audio: false
                };
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((sendStream) => {
                        let videoTrack = null
                        sendStream.getTracks().forEach((track) => {
                            if (track.kind == "video") {
                                videoTrack = track
                            }
                        });
                        sendStream.removeTrack(videoTrack)
                        that.mediaStream.addTrack(videoTrack)
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    });
            } else {
                reject()
            }
        })
        return promise

    }

    createScreen(width, height, fps) {
        const that = this
        const promise = new Promise((resolve, reject) => {
            if (that.VideoTrack() == null) {
                const constraints = { video: { width: width, height: height, frameRate: fps } }
                navigator.mediaDevices.getDisplayMedia(constraints)
                    .then((sendStream) => {
                        let videoTrack = null
                        sendStream.getTracks().forEach((track) => {
                            if (track.kind == "video") {
                                videoTrack = track
                            }
                        });
                        sendStream.removeTrack(videoTrack)
                        that.mediaStream.addTrack(videoTrack)
                        resolve()
                    })
                    .catch(e => {
                        reject(e)
                    });
            } else {
                reject()
            }
        })
        return promise
    }


    sendStreamToTag(...tags) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": tags,
                "1": that.Id(),
                "2": "this"
            }
            that.connect.createMsgWebrtcMethod("SendStreamToTag", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code,
                        data: result.data,
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    getStreamInfo() {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": "this"
            }
            that.connect.createMsgWebrtcMethod("GetStreamInfo", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code,
                        data: result.data,
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    getUserStreamInfo(...uids) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": uids,
                "1": "this",
            }
            that.connect.createMsgWebrtcMethod("GetUserStreamInfo", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code,
                        data: result.data,
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    sendStreamStopToTag(...tags) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": tags,
                "1": that.Id(),
                "2": that.Name(),
                "3": "this"
            }
            that.connect.createMsgWebrtcMethod("SendStreamStopToTag", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code,
                        data: result.data,
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    changeStreamName(streamName) {
        this.streamName = streamName
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": that.Id(),
                "1": that.Name(),
                "2": "this"
            }
            that.connect.createMsgWebrtcMethod("ChangeStreamName", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code,
                        data: result.data,
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    message(streamInfo) {
        if (streamInfo.info === 8) {
            try {
                let data = this.changeStreamName(this.Name())
                this.resolve(data)
                this.resolve = null
            } catch (e) {
                this.reject(e)
                this.reject = null
            }
        }
    }

    changeBitrate(bitrate) {
        if (this.videoSender != null) {
            const parameters = this.videoSender.getParameters();
            if (!parameters.encodings) {
                parameters.encodings = [{}];
            }
            if (bitrate == undefined || bitrate == null) {
                delete parameters.encodings[0].maxBitrate;
            } else {
                parameters.encodings[0].maxBitrate = bitrate;
            }
            this.videoSender.setParameters(parameters)
        }
    }

    rtmpPush(url) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": that.Id(),
                "1": url,
                "2": "this"
            }
            that.connect.createMsgWebrtcMethod("RtmpPushStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    stopRtmpPush() {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": that.Id(),
                "1": "this"
            }
            that.connect.createMsgWebrtcMethod("StopRtmpPushStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    record(fileName, autoRecord) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": that.Id(),
                "1": fileName,
                "2": autoRecord,
                "3": "this"
            }
            that.connect.createMsgWebrtcMethod("RecordStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    stopRecord(fileName) {
        const that = this
        if(fileName == undefined){
            fileName = that.Id()
        }
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": fileName,
                "1": "this"
            }
            that.connect.createMsgWebrtcMethod("StopRecordStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    push(bitrate, videoType) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const localConnection = that.connect.localConnection
            if (that.AudioTrack() != null) {
                that.audioSender = localConnection.addTrack(that.AudioTrack(), that.mediaStream)
            }
            if (that.VideoTrack() != null) {
                let sendEncodings = [{}]
                if (bitrate != undefined && bitrate != null) {
                    sendEncodings = [{
                        maxBitrate: bitrate,
                    }]
                }
                let rtcTransceiver = await localConnection.addTransceiver(that.mediaStream.getVideoTracks()[0], {
                    streams: [that.mediaStream],
                    sendEncodings: sendEncodings,
                });
                that.videoSender = rtcTransceiver.sender
                that.rtcTransceiver = rtcTransceiver
                if (videoType != undefined && videoType != null) {
                    const capabilities = RTCRtpSender.getCapabilities('video').codecs;
                    capabilities.forEach((codec, idx) => {
                        if (codec.mimeType === videoType) {
                            capabilities.splice(idx, 1);
                            capabilities.unshift(codec);
                        }
                    });
                    rtcTransceiver.setCodecPreferences(capabilities);
                }
                // that.videoSender = localConnection.addTrack(that.videoTrack, that.mediaStream)
            }
            const offer = await this.connect.getOffer()
            const params = {
                "0": JSON.stringify(offer),
                "1": "this"
            }
            that.connect.createMsgWebrtcMethod("PushUpdateSdp", params, (result) => {
                if (result.code == 200) {
                    const sdp = JSON.parse(result.data)
                    that.connect.setSdp(sdp).then(() => {
                        that.resolve = resolve
                        that.reject = reject
                    }).catch(e => {
                        reject(e)
                    })

                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    stopPush() {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const localConnection = that.connect.localConnection
            const mid = that.rtcTransceiver.mid
            that.rtcTransceiver.stop()
            // if (that.audioSender != null) {
            //     localConnection.removeTrack(that.audioSender)
            //     that.audioSender = null
            // }
            // if (that.videoSender != null) {
            //     localConnection.removeTrack(that.videoSender)
            //     that.videoSender = null
            // }
            const offer = await this.connect.getOffer()
            const params = {
                "0": JSON.stringify(offer),
                "1": mid,
                "2": "this"
            }
            that.connect.createMsgWebrtcMethod("StopPushUpdateSdp", params, (result) => {
                if (result.code == 200) {
                    const sdp = JSON.parse(result.data)
                    that.connect.setSdp(sdp)
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    stopWebCode(fileName) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": fileName,
            }
            that.connect.createMsgWebCodecs("CloseStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }

    resumeWebCode(fileName) {
        const that = this
        const promise = new Promise(async (resolve, reject) => {
            const params = {
                "0": fileName
            }
            that.connect.createMsgWebCodecs("ResumeStream", params, (result) => {
                if (result.code == 200) {
                    resolve({
                        code: result.code
                    })
                } else {
                    reject(new Error(JSON.stringify({
                        code: result.code,
                        errMsg: result.errMsg
                    })))
                }
            })
        })
        return promise
    }


    close() {
        if (this.AudioTrack() != null) {
            this.AudioTrack().stop()
            this.mediaStream.removeTrack(this.AudioTrack())
        }
        if (this.VideoTrack() != null) {
            this.VideoTrack().stop()
            this.mediaStream.removeTrack(this.VideoTrack())
        }
        this.connect.unRegisterLocalStream(this)
        this.mediaStream = null
    }

}
